// extracted by mini-css-extract-plugin
export var alignLeft = "m_qh d_bG";
export var alignCenter = "m_bP d_bD";
export var alignRight = "m_qj d_bH";
export var textAlignLeft = "m_qk";
export var textAlignCenter = "m_ql";
export var textAlignRight = "m_qm";
export var embedInnerWrapperDesign1 = "m_qn d_bM";
export var embed = "m_qp d_b1";
export var titleMargin = "m_qq d_cw d_dw";
export var subtitleMargin = "m_qr d_cs d_dw";
export var paragraphMargin = "m_qs d_cw d_dw";
export var SubtitleSmall = "m_qd s_qd s_rq s_rC";
export var SubtitleNormal = "m_qf s_qf s_rq s_rD";
export var SubtitleLarge = "m_qg s_qg s_rq s_rF";
export var BodySmall = "m_qt s_qt s_rq s_rK";
export var BodyNormal = "m_qv s_qv s_rq s_rL";
export var BodyLarge = "m_qw s_qw s_rq s_rM";