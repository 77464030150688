// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "q_rd d_bD";
export var storyRowWrapper = "q_hx d_hx d_bK";
export var storyLeftWrapper = "q_rf d_bz d_bP";
export var storyWrapperFull = "q_rg d_cD";
export var storyWrapperFullLeft = "q_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "q_mv d_hy";
export var storyLeftWrapperCenter = "q_rh d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "q_rj d_hF";
export var storyHeader = "q_rk d_hD d_w d_cs";
export var storyHeaderCenter = "q_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "q_hB d_hB d_by d_dw";
export var storyBtnWrapper = "q_rl d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "q_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "q_rm d_fg d_Z";
export var imageWrapperFull = "q_rn d_w d_H d_bf d_Z";
export var SubtitleSmall = "q_qd s_qd s_rq s_rC";
export var SubtitleNormal = "q_qf s_qf s_rq s_rD";
export var SubtitleLarge = "q_qg s_qg s_rq s_rF";
export var textLeft = "q_dv";
export var textCenter = "q_dw";
export var textRight = "q_dx";