// extracted by mini-css-extract-plugin
export var tileContent = "p_qZ d_w d_H d_Z";
export var teamTextLeft = "p_q0 d_dv";
export var teamTextCenter = "p_q1 d_dw";
export var teamTextRight = "p_q2 d_dx";
export var alignLeft = "p_qh d_w d_bz d_fp d_bG d_dv";
export var alignCenter = "p_bP d_w d_bz d_fq d_bD d_dw";
export var alignRight = "p_qj d_w d_bz d_fr d_bH d_dx";
export var teamContainer = "p_q3 d_dW";
export var teamContainerFull = "p_q4 d_dT";
export var teamRowWrapper = "p_q5 d_cc";
export var teamTileWrapper = "p_j3 d_j3 d_Z d_cv";
export var teamTileSquareWrapper = "p_q6 d_j4 d_Z d_cv";
export var teamTileRoundWrapper = "p_j4 d_j4 d_Z d_cv";
export var teamTileNoGuttersWrapper = "p_j5 d_j5 d_Z";
export var teamHoverNoGutters = "p_j6 d_j6 d_0 d_w d_H d_bz d_bD d_bP d_bm";
export var teamImageText = "p_kc d_kc d_bl d_w d_by";
export var teamInfoWrapperSquare = "p_q7 d_j7";
export var teamInfoWrapperRound = "p_j7 d_j7";
export var teamInfoWrapper = "p_q8 d_j8 d_0";
export var teamInfoWrapperNoGutters = "p_j9 d_j9 d_w d_H d_bD d_bM d_bJ d_c7";
export var teamImgWrapper = "p_jZ d_jZ";
export var teamImgWrapperAlt = "p_j0 d_j0";
export var teamImgWrapperNoGutters = "p_kb d_kb";
export var teamHeader = "p_q9 d_cw";
export var teamHeaderAlt = "p_rb d_cw";
export var teamHeaderNoGutters = "p_rc d_cw d_cD";